
import React from "react";

import Cabecalho from "../../components/Cabecalho/Cabecalho";

const DashboardUsuario = () => {
            

    return ( 

    <>
        <Cabecalho texto={"Gerenciador de Boletins Digitais: versão(beta)"}/>
    </> 
    
    );
}
 
export default DashboardUsuario;