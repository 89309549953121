import React from "react";

import "./LogoPMMA.css"

import logoPMMA from "./logo_pmma.png"
const LogoPMMA = () => {
    return ( < >
        
            <img src={logoPMMA} className="Logo-PMMA" alt="Logo da PMMA" />

    </> )
}
 
export default LogoPMMA;