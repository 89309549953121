import React from "react";

function page404(){

    return(
        <>
            <h1>
                <a href="/">Aconteceu algo inesperado volte ao incio clicando aqui...</a>
            </h1>
        </>
    )
}

export default  page404