import React from "react";

import "./Logo4BPM.css"

import logo4bpm from "./Logo_4bpm.png"

const Logo4BPM = ()=>{

    return(
        <>
            <img src={logo4bpm} className="Logo-4bpm" alt="Logo do 4°BPM da PMMA" />
        </>
    )
}

export default Logo4BPM